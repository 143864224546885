import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Nav, NavItem, NavLink, Col, Tooltip } from 'reactstrap';
import * as Icon from 'react-feather';
import { AuthContext } from '../../context/authContext';
import ComponentCard from '../../layouts/containers/ComponentCard';
import PestañasDatos from '../../components/users/PestañasDatos';
import Matriculaciones from '../../components/users/Matriculaciones';
import Ciclos from '../../components/users/Ciclos';
import PeticionMatricula from '../../components/users/PeticionMatricula';
import { SearchContext } from '../../context/searchContext';
import alumnosApiService from '../../services/http/alumnosApiService';
import d2lApiService from '../../services/http/d2lApiService';
import matriculacionApiService from '../../services/http/matriculacionApiService';

const DetallesUsuarios = () => {
  const navigate = useNavigate();
  // Id del alumno a mostrar.
  const { studentId, empresaId } = useParams();

  // Obtenemos la fecha actual
  let date = new Date();
  date = `${date.getFullYear()}-${date.getMonth()}-${date.getDay()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;

  // Seteamos el last update
  localStorage.setItem('lastUpdate', JSON.stringify(date));

  // Preparamos variables
  const { userOU } = useContext(AuthContext);
  const { saveSearches, searchContext } = useContext(SearchContext);

  // Preparamos estados
  const [missingData, setMissingData] = useState(false);
  const [missingDataT, setMissingDataT] = useState(false);
  const [missingDataE, setMissingDataE] = useState(false);
  const [dataD2L, setDataD2L] = useState();
  const [activeTab, setActiveTab] = useState('1');
  const prevActiveTab = useRef(null);
  const [dataApi, setDataApi] = useState(null);
  const [balance, setBalance] = useState(0);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [recibosPendientes, setRecibosPendientes] = useState(false);
  const [dataCharged, setDataCharged] = useState(false);

  // Peticiones API
  useEffect(() => {
    const fetchAlumnoData = async () => {
      if (userOU.idCedesca !== -4 && userOU.idCntec !== -4 && !dataCharged) {
        try {
          const student = await alumnosApiService.getAlumnoById(studentId, {
            headquarter: empresaId,
          });

          if (localStorage.getItem('student')) localStorage.removeItem('student');
          localStorage.setItem('student', JSON.stringify(student));

          if (JSON.parse(localStorage.getItem('student')).idD2L !== null) {
            const d2lData = await d2lApiService.obtenerAlumno({
              idD2L: JSON.parse(localStorage.getItem('student')).idD2L,
            });
            setDataD2L(d2lData);
          }
        } catch (error) {
          // No hacer nada específico aquí.
        } finally {
          setDataCharged(true);
        }

        try {
          const balanceResp = await matriculacionApiService.consultarBalanceGeneral({
            headquarter: empresaId,
            id: studentId,
          });
          setBalance(parseFloat(balanceResp.Balance));
        } catch (error) {
          // No hacer nada específico aquí.
        }

        try {
          const actualData = await alumnosApiService.cargarAlumno({
            id: studentId,
            headquarter: empresaId,
            idCedesca: userOU.idCedesca,
            idCntec: userOU.idCntec,
          });
          setDataApi({
            ...actualData,
            datos: {
              ...actualData.datos,
              personales: {
                ...actualData.datos.personales,
                lopdSede:
                  actualData.datos.personales.lopdSede === null
                    ? ''
                    : actualData.datos.personales.lopdSede,
                lopdComunicaciones:
                  actualData.datos.personales.lopdComunicaciones === null
                    ? ''
                    : actualData.datos.personales.lopdComunicaciones,
                lopdFecha:
                  actualData.datos.personales.lopdFecha === null
                    ? ''
                    : actualData.datos.personales.lopdFecha,
              },
              escolares: {
                ...actualData.datos.escolares,
                idioma:
                  actualData.datos.escolares.idioma === '' ||
                  actualData.datos.escolares.idioma === null
                    ? 'Español'
                    : actualData.datos.escolares.idioma,
                idalu:
                  actualData.datos.escolares.idalu === null ? '' : actualData.datos.escolares.idalu,
                sede:
                  actualData.datos.escolares.sede === null ? '' : actualData.datos.escolares.sede,
                nee: actualData.datos.escolares.nee === null ? '' : actualData.datos.escolares.nee,
                neeComment:
                  actualData.datos.escolares.neeComment === null
                    ? ''
                    : actualData.datos.escolares.neeComment,
                tsi: actualData.datos.escolares.tsi === null ? '' : actualData.datos.escolares.tsi,
                situacionLaboral:
                  actualData.datos.escolares.situacionLaboral === null
                    ? ''
                    : actualData.datos.escolares.situacionLaboral,
                formacionAcademica:
                  actualData.datos.escolares.formacionAcademica === null
                    ? ''
                    : actualData.datos.escolares.formacionAcademica,
                antiguoCentro:
                  actualData.datos.escolares.antiguoCentro === null
                    ? ''
                    : actualData.datos.escolares.antiguoCentro,
                antiguosEstudios:
                  actualData.datos.escolares.antiguosEstudios === null
                    ? ''
                    : actualData.datos.escolares.antiguosEstudios,
                notaMedia:
                  actualData.datos.escolares.notaMedia === null
                    ? ''
                    : actualData.datos.escolares.notaMedia,
                siguientesEstudios:
                  actualData.datos.escolares.siguientesEstudios === null
                    ? ''
                    : actualData.datos.escolares.siguientesEstudios,
                formaAcceso:
                  actualData.datos.escolares.formaAcceso === null
                    ? ''
                    : actualData.datos.escolares.formaAcceso,
                emailCorp:
                  actualData.datos.escolares.emailCorp === null
                    ? ''
                    : actualData.datos.escolares.emailCorp,
                fechaUltimoCurso:
                  actualData.datos.escolares.fechaUltimoCurso === null
                    ? ''
                    : actualData.datos.escolares.fechaUltimoCurso,
              },
            },
          });
        } catch (error) {
          // No hacer nada específico aquí.
        }
      }
    };

    fetchAlumnoData();
  }, [userOU]);

  // Funciones
  const toggle = (tab) => {
    if (activeTab !== tab) {
      prevActiveTab.current = activeTab;
      setActiveTab(tab);
    }
  };

  const handleOnClick = () => {
    const search = searchContext;
    search.newSearch = false;
    saveSearches(search);
    navigate('/alumnos');
  };

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  if (!dataCharged) return null;

  return (
    <>
      <Col md="12">
        <ComponentCard
          title="Gestión de alumnos"
          dropdown
          handleClick={handleOnClick}
          hasButton={false}
          isProfile
          dataD2L={dataD2L}
        >
          <Nav tabs>
            <NavItem>
              <NavLink
                className={activeTab === '1' ? 'active' : ''}
                onClick={() => {
                  toggle('1');
                }}
              >
                Datos personales
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === '2' ? 'active' : ''}
                onClick={() => {
                  toggle('2');
                }}
              >
                Datos escolares
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === '3' ? 'active' : ''}
                onClick={() => {
                  toggle('3');
                }}
              >
                Datos tutores
              </NavLink>
            </NavItem>
            {dataApi !== null &&
            dataApi.datos.personales.driveId !== null &&
            dataApi.datos.personales.driveId !== '' ? (
              <NavItem>
                <NavLink
                  className={activeTab === '4' ? 'active' : ''}
                  onClick={() => {
                    toggle('4');
                  }}
                >
                  Documentación
                </NavLink>
              </NavItem>
            ) : (
              ''
            )}
            {userOU.centro === 'SUPERADMIN' ||
            userOU.centro === 'CNTEC Girona' ||
            userOU.centro === 'CEDESCA' ||
            userOU.centro === 'CNTEC Sevilla' ||
            userOU.centro === 'SEDE' ||
            userOU.centro === 'SECRETARÍA' ||
            userOU.centro === 'ORIENTACIÓN' ? (
              <NavItem>
                <NavLink
                  className={activeTab === '5' ? 'active' : ''}
                  onClick={() => {
                    toggle('5');
                  }}
                >
                  SEPA
                </NavLink>
              </NavItem>
            ) : (
              ''
            )}
            {userOU.centro === 'SUPERADMIN' ||
            userOU.centro === 'CNTEC Girona' ||
            userOU.centro === 'CEDESCA' ||
            userOU.centro === 'CNTEC Sevilla' ||
            userOU.centro === 'SEDE' ||
            userOU.centro === 'SECRETARÍA' ||
            userOU.centro === 'ORIENTACIÓN' ? (
              <NavItem>
                <NavLink
                  className={activeTab === '6' ? 'active' : ''}
                  onClick={() => {
                    toggle('6');
                  }}
                  style={{ display: 'flex', alignItems: 'center', gap: '4px' }}
                >
                  Contabilidad{' '}
                  {recibosPendientes && <Icon.AlertCircle id="Tooltip" color="red" size={22} />}
                  {recibosPendientes && (
                    <Tooltip
                      placement="top"
                      isOpen={tooltipOpen}
                      target="Tooltip"
                      toggle={toggleTooltip}
                    >
                      Este alumno tiene recibos pendientes
                    </Tooltip>
                  )}
                </NavLink>
              </NavItem>
            ) : (
              ''
            )}
            {userOU.centro === 'SUPERADMIN' ||
            userOU.centro === 'CNTEC Girona' ||
            userOU.centro === 'CEDESCA' ||
            userOU.centro === 'CNTEC Sevilla' ||
            userOU.centro === 'SEDE' ||
            userOU.centro === 'SECRETARÍA' ||
            userOU.centro === 'ORIENTACIÓN' ? (
              <NavItem>
                <NavLink
                  className={activeTab === '7' ? 'active' : ''}
                  onClick={() => {
                    toggle('7');
                  }}
                  style={{ display: 'flex', alignItems: 'center', gap: '4px' }}
                >
                  Seguimiento
                </NavLink>
              </NavItem>
            ) : (
              ''
            )}
          </Nav>
          <PestañasDatos
            setRecibosPendientes={setRecibosPendientes}
            prevActiveTab={prevActiveTab.current}
            activeTab={activeTab}
            dataApi={dataApi}
            missingDataFunction={setMissingData}
            missingDataFunctionE={setMissingDataE}
            missingDataFunctionT={setMissingDataT}
            dataD2L={dataD2L}
          />
        </ComponentCard>
        {dataApi !== null ? <Matriculaciones matriculas={dataApi.matriculas} /> : ''}
        {dataApi !== null ? (
          <PeticionMatricula
            recibosPendientes={recibosPendientes}
            missingData={missingData}
            missingDataE={missingDataE}
            missingDataT={missingDataT}
          />
        ) : (
          ''
        )}
        {dataApi !== null && dataApi.ciclos.length > 0 ? (
          <Ciclos
            recibosPendientes={recibosPendientes}
            ciclos={dataApi.ciclos}
            datos={dataApi.datos}
            matriculas={dataApi.matriculas}
            balance={balance}
          />
        ) : (
          ''
        )}
      </Col>
    </>
  );
};

export default DetallesUsuarios;
