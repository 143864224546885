import { useEffect, useState } from "react"
import { Input } from "reactstrap" 
import PropTypes from 'prop-types';
import matriculacionApiService from "../../../services/http/matriculacionApiService";

const MetodosPago = ({setIsLoading, isLoading, actual, idEditados, setIdEditados,numRecibo}) => {
    const [data, setData] = useState('')
   
    useEffect(() => {
      const fetchData = async () => {
        try {
          const dataMetodosPago = await matriculacionApiService.cargarMetodosPago({
            headquarter: 'CEDESCA',
          });
          setData(dataMetodosPago);
        } catch (error) {
          setData('');
        } finally {
          setIsLoading(false);
        }
      };

      fetchData();
    }, []);

    const handleOnChange = (e) => {
        const nuevoArray = idEditados.filter(objeto => objeto.id !== e.target.id);
        const reciboEditado = {
            id: e.target.id,
            forma: e.target.value,
        }
        setIdEditados([...nuevoArray, reciboEditado])
    }

    return (
       
        <>
            {isLoading || data.length === 0 ? '' : <Input type="select" name={numRecibo}  id={numRecibo}  onChange={handleOnChange} value={actual} style={{
        width: "150px",
        height: "30px",
        fontSize: "13px",
        padding: "5px",
      }}>
                            {data.map(datos => <option key={datos.id} value={datos.id}>{datos.descripcion}</option>)}
            </Input> }
            
        </>
    )
}
MetodosPago.propTypes = {
    isLoading: PropTypes.any,
    setIsLoading: PropTypes.any,
    actual: PropTypes.any,
    idEditados: PropTypes.any,
    setIdEditados: PropTypes.any,
    numRecibo: PropTypes.any,
  };
export default MetodosPago