import React, { useState } from 'react';
import { Button, Input } from 'reactstrap';
import ReactTable from 'react-table-v6';
import PropTypes from 'prop-types';

const ListadoAlumnos = ({ alumnos, grupo, selectedEmails, setSelectedEmails}) => {
    const translations = {
        previousText: '<',
        nextText: '>',
        loadingText: 'Cargando..',
        ofText: 'de',
        rowsText: 'líneas',
        noDataText: 'Datos no encontrados',
        pageText: 'Página',
    };

    const [allSelected, setAllSelected] = useState(false); // Estado para controlar si todos están seleccionados
  
    const handleCheckboxChange = (codigo, email) => {
        
        const updatedEmails = { ...selectedEmails };
        if (updatedEmails[codigo]) {
            delete updatedEmails[codigo]; // Si ya está seleccionado, deselecciona
        } else {
            updatedEmails[codigo] = email; // Si no está seleccionado, añade el email
        }
        setSelectedEmails(updatedEmails);
    };

    const toggleSelectAll = () => {
        if (allSelected) {
            setSelectedEmails({}); // Desmarcar todos
        } else {
            const allEmails = {};
            alumnos.forEach(alumno => {
                let emailToSelect = '';
                switch (grupo) {
                    case 0:
                        emailToSelect = alumno.Email_personal;
                        break;
                    case 1:
                        emailToSelect = alumno.Email_Cedetec;
                        break;
                    case 2:
                        emailToSelect = alumno.Tutor1_Mail;
                        break;
                    case 3:
                        emailToSelect = alumno.Tutor2_Mail;
                        break;
                    default:
                        break;
                }
                allEmails[alumno.CODIGO] = emailToSelect; // Guarda el email
            });
            setSelectedEmails(allEmails); // Marcar todos
        }
        setAllSelected(!allSelected); // Cambiar el estado de selección
    };

    return (
        <>
        
           

            {alumnos.length > 0 ? (
                 <><Button onClick={toggleSelectAll}>
                 {allSelected ? 'Desmarcar Todo' : 'Marcar Todo'}
             </Button>
                <ReactTable
                    {...translations}
                    columns={[
                        {
                            Header: 'Seleccionar',
                            accessor: 'actions',
                            Cell: (row) => {
                                const email = 
                                    grupo === 0 ? row.original.Email_personal :
                                    grupo === 1 ? row.original.Email_Cedetec :
                                    grupo === 2 ? row.original.Tutor1_Mail :
                                    grupo === 3 ? row.original.Tutor2_Mail : '';
                                    
                                return (
                                    <Input
                                        type="checkbox"
                                        checked={!!selectedEmails[row.original.CODIGO]}
                                        onChange={() => handleCheckboxChange(row.original.CODIGO, email)}
                                    />
                                );
                            },
                        },
                        {
                            Header: 'Código',
                            accessor: 'CODIGO',
                        },
                        {
                            Header: 'Sede',
                            accessor: 'SEDE',
                        },
                        {
                            Header: 'Nombre',
                            accessor: 'NOMBRE_ALUMNO',
                        },
                        {
                            Header: 'Apellidos',
                            accessor: 'APELLIDOS',
                        },
                        {
                            Header: 'Email Cedetec',
                            accessor: 'Email_Cedetec',
                        },
                        {
                            Header: 'Email Personal',
                            accessor: 'Email_personal',
                        },
                        {
                            Header: 'Tutor 1 Email',
                            accessor: 'Tutor1_Mail',
                        },
                        {
                            Header: 'Tutor 2 Email',
                            accessor: 'Tutor2_Mail',
                        },
                        {
                            Header: 'DNI',
                            accessor: 'DNI',
                        },
                    ]}
                    data={alumnos}
                    defaultPageSize={10}
                    showPaginationBottom
                    className="-striped -highlight mt-5 text-center"
                />
              
                </>
            ) : (
                ''
            )}
        </>
    );
};

ListadoAlumnos.propTypes = {
    alumnos: PropTypes.array.isRequired,
    grupo: PropTypes.number.isRequired,
    selectedEmails: PropTypes.any,
    setSelectedEmails: PropTypes.any
};

export default ListadoAlumnos;
