import ReactTable from 'react-table-v6';
import PropTypes from 'prop-types'

const TablaTutores = (props) => {

    const translations = {
        previousText: '<',
        nextText: '>',
        loadingText: 'Cargando..',
        ofText: 'de',
        rowsText: 'líneas',
        noDataText: 'Datos no encontrados',
        pageText: 'Página',
      };
    
    return (

        <ReactTable
        {...translations}
        columns={[
        {Header: 'Seleccionar',
        accessor: 'actions',
      sortable: false},
          {
            Header: 'ID',
            accessor: 'id',
          },
          {
            Header: 'Empresa',
            accessor: 'empresaId',
          },
          {
            Header: 'Ciclo',
            accessor: 'ciclo',
          },
          {
            Header: 'Alumno',
            accessor: 'nombre_alumno',
          },
          {
            Header: 'Email tutor 1',
            accessor: 'email_tut1',
          },
          {
            Header: 'Nombre tutor 1',
            accessor: 'nombre_tut1',
          },
          {
            Header: 'Email tutor 2',
            accessor: 'email_tut2',
          },
          {
            Header: 'Nombre tutor 2',
            accessor: 'nombre_tut2',
          },
          
        ]}
        data={props.data}
        defaultPageSize={10}
        showPaginationBottom
        className="-striped -highlight mt-5 text-center"
        showPageSizeOptions={false}  // Deshabilita las opciones de cambio de tamaño
        pageSize={props.data.length === 0 ? 10 : props.data.length}   // Establece el tamaño de página a 50
      />
    )
}
TablaTutores.propTypes = {
    data: PropTypes.any,
};
export default TablaTutores