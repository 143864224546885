import { Link } from 'react-router-dom';
import { Button } from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux';
import React from 'react';
import { ToggleMobileSidebar } from '../../store/customizer/CustomizerSlice';
import logo from '../../assets/images/logos/logo.png'


const Logo = () => {
  const toggleMiniSidebar = useSelector((state) => state.customizer.isMiniSidebar);
  const dispatch = useDispatch();

  return (
    <Link to='/alumnos' className="d-flex align-items-center gap-2">
      {toggleMiniSidebar ?
        <img src={logo} alt="Logo corporativo" width="50" /> : <>
          <img src={logo} alt="Logo corporativo" width="100" />
          <div className="d-sm-auto d-sm-block d-lg-none">
          <Button
            close
            size="sm"
            
            onClick={() => dispatch(ToggleMobileSidebar())}
          />
          </div>
          
        </>}

    </Link>
  );
};

export default Logo;
