import PropTypes from 'prop-types'
import React, { useState, useContext } from 'react';
import { Modal, ModalBody, ModalFooter, Button, Label, FormGroup, Input} from 'reactstrap';
import Documentos from '../../components/users/Documentos';
import { AuthContext } from '../../context/authContext'
import matriculacionApiService from '../../services/http/matriculacionApiService';

const ModalText = (props) => {
    
    const [comment, setComment] = useState(props.motivoBaja);
    const enrollmentSession = JSON.parse(localStorage.getItem('enrollment'));
    const { userOU } = useContext( AuthContext );



    const handleCommentChange = (e) => {
        setComment(e.target.value);
    };

    const handleSubmit = async (rechazo) => {
      // Handle the submission of the comment
      let estado = 8;

      // Si somos secretaría procesamos la petición
      if (userOU.centro === 'SUPERADMIN' || userOU.centro === 'SECRETARÍA') {
        if (rechazo) {
          estado = 1;
        } else {
          estado = 3;
        }
      }

      try {
        await matriculacionApiService.bajaMatricula({
          headquarter: enrollmentSession.headquarter,
          id: enrollmentSession.id,
          comment,
          nombreCiclo: props.curso,
          estado,
        });

        props.setState(false);
        // Aquí puedes realizar otras acciones después de setIsShown(true) si es necesario.
        window.location.reload();
      } catch (error) {
        props.setState(true);
      }
    };

    const handleOnClose = () => {
        props.setState(false)
    }

    return (
        <div>
            <Modal isOpen={props.state} centered size='lg' zIndex={100}>
                <ModalBody>
                    <Label htmlFor="dateCreate"><b>Comentario de baja</b></Label>
                    {
                        props.estadoMatricula === 'Pendiente baja' ? <p>
                        {props.motivoBaja}
                        </p> : <FormGroup>
                        <Input
                            type="textarea"
                            name="text"
                            id="comentarioBaja"
                            className="mb-3"
                            onChange={(e) => handleCommentChange(e)}
                        />
                        <Documentos datosDocumento={props.doc} />
                        </FormGroup>
                    }
                    
                </ModalBody>
                <ModalFooter>
                    {
                        props.estadoMatricula === 'Pendiente baja' ? <>
                        <Button color='danger' onClick={() => handleSubmit(false)}>Dar de baja</Button>
                        <Button color='warning' onClick={() => handleSubmit(true)}>Rechazar</Button>
                        </>
                        :
                        <Button color='danger' onClick={() => handleSubmit(false)} disabled = { comment === '' || comment === null ? 'disabled' : ''}> {userOU.centro === 'SECRETARÍA' || userOU.centro === 'SUPERADMIN' ? 'Dar de baja'  : 'Solicitar baja'}</Button> 
                    }
                    
                    <Button onClick = {handleOnClose} >Cancelar</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

ModalText.propTypes = {
    state: PropTypes.any,
    setState: PropTypes.any,
    estadoMatricula: PropTypes.any,
    motivoBaja: PropTypes.any,
    curso: PropTypes.any,
    doc: PropTypes.any
};
export default ModalText;
