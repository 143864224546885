import ReactTable from 'react-table-v6';
import PropTypes from 'prop-types'

const ListadoAlumnosActas = (props) => {

    const translations = {
        previousText: '<',
        nextText: '>',
        loadingText: 'Cargando..',
        ofText: 'de',
        rowsText: 'líneas',
        noDataText: 'Datos no encontrados',
        pageText: 'Página',
      };
    return (
        <ReactTable
        {...translations}
        columns={[
        {Header: 'Seleccionar',
        accessor: 'actions',
      sortable: false},
      {
        Header: 'ID',
        accessor: 'ccodcli',
      },
          {
            Header: 'Nombre',
            accessor: 'sNombre',
          },
          {
            Header: 'Apellidos',
            accessor: 'sApellidos',
          },
          {
            Header: 'Documento',
            accessor: 'CDNICIF',
          },
          
        ]}
        data={props.data}
        defaultPageSize={10}
        showPaginationBottom
        className="-striped -highlight mt-5 text-center"
        showPageSizeOptions={false}  // Deshabilita las opciones de cambio de tamaño
        
      />
    )
}
ListadoAlumnosActas.propTypes = {
    data: PropTypes.any,
};
export default ListadoAlumnosActas