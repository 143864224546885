import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Col, Row, FormGroup, Label, Input, Button } from 'reactstrap';
import PropTypes from 'prop-types'; // Importa PropTypes
import alumnosApiService from '../../services/http/alumnosApiService';
import envioEmailService from '../../services/http/envioEmailService'


const FiltrarAlumnos = ({setAlumnos, setIsLoading,setEmailGroup, isLoading, setSelectedEmails}) => {
  const [empresaId, setEmpresaId] = useState('CEDESCA');
  const [formationCenter, setFormationCenter] = useState([]);
  const [idFormationCenter, setIdFormationCenter] = useState([]);
  const [groupList, setGroupList]= useState([]);
  const [group, setGroup]= useState([]);


 
  useEffect(() => {
    const cargarOpciones = async () => {
      try {
        const dataCentros = await alumnosApiService.cargarCentrosFormacion({
          empresaId,
        });

        // Mapea dataCentros al formato que react-select requiere
        const arrayFiltrado = dataCentros.map((centro) => ({
          value: centro.Id, // o la propiedad que corresponda
          label: centro.Nombre, // o la propiedad que corresponda
        }));

        const nuevoObjeto = { value: '-1', label: '' }; // Objeto de ejemplo añadido
        arrayFiltrado.unshift(nuevoObjeto);
        setFormationCenter(arrayFiltrado);
      } catch (error) {
        setFormationCenter([]);
      }
    };
    cargarOpciones();
  }, [empresaId]); // Agregar empresaId como dependencia para actualizar cuando cambie

  useEffect(() => {
    const cargarOpciones = async () => {
        
      try {
  
        const dataCentros = await envioEmailService.consultaGrupos({
          headquarter: empresaId, // Ensure you're passing the right key here
         // Convert the array to a comma-separated string
        });
  
        const arrayFiltrado = dataCentros.map((centro) => ({
          value: centro.codigogrupo, // Adjust this based on the actual response structure
          label: centro.descripcion2, // Adjust this based on the actual response structure
        }));
  
        const nuevoObjeto = { value: '-1', label: '' }; // Optional empty object
        arrayFiltrado.unshift(nuevoObjeto);
        setGroupList(arrayFiltrado);
      } catch (error) {
        console.error('Error fetching grupos:', error);
        setGroupList([]);
      }
    };
  
    cargarOpciones(); // Call the function
  
  }, [empresaId]);

  const handleEmpresaChange = (e) => {
    setEmpresaId(e.target.value);
    console.log('hola')
  };

  const CustomClearText = () => 'borrar todo';

  const ClearIndicator = (props) => {
    const {
      children = <CustomClearText />,
      getStyles,
      innerProps: { ref, ...restInnerProps },
    } = props;

    return (
      <div {...restInnerProps} ref={ref} style={getStyles('clearIndicator', props)}>
        <div style={{ padding: '0px 5px' }}>{children}</div>
      </div>
    );
  };

  const handleOnClick = () => {
    setIsLoading(true)
    setSelectedEmails({})
    const cargarOpciones = async () => {
    try {
      
      const dataAlumnos = await envioEmailService.consultaAlumnosFiltrados({
        headquarter: empresaId, 
        listadoCentros: idFormationCenter,
        listadoGrupos: group
      });

      setIsLoading(false)
     setAlumnos(dataAlumnos)
    } catch (error) {
      console.error('Error fetching grupos:', error);
      setAlumnos([]);
    }
  }
  cargarOpciones()
}

  // Agregar prop-types para ClearIndicator
  ClearIndicator.propTypes = {
    children: PropTypes.node, // children puede ser cualquier tipo de nodo
    getStyles: PropTypes.func.isRequired, // getStyles es una función requerida
    innerProps: PropTypes.shape({
      ref: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    }).isRequired, // innerProps es un objeto que contiene ref
  };

  const handleFormationCenter = (selected) => {
    // Extraer los IDs (value) de las opciones seleccionadas
    const selectedIds = selected ? selected.map((option) => option.value) : [];
    setIdFormationCenter(selectedIds); // Actualiza el estado con los IDs seleccionados
  };

  const handleGroup = (selected) => {
    // Extraer los IDs (value) de las opciones seleccionadas
    const selectedIds = selected ? selected.map((option) => option.value) : [];
    setGroup(selectedIds); // Actualiza el estado con los IDs seleccionados
  };

  const handleEmailGroupChange = (e) => {
    setEmailGroup(e.target.value);
  };



  return (
    <>
      <Row>
        {/* Centro Select */}
        <Col md="4">
          <FormGroup>
            <Label for="centroSelect">Centro</Label>
            <Input type="select" name="centro" id="centroSelect" onChange={handleEmpresaChange}>
              <option value="CEDESCA">Cedesca</option>
              <option value="CNTEC">Cntec</option>
            </Input>
          </FormGroup>
        </Col>

        {/* Delegación Select */}
        <Col md="4">
          <FormGroup>
            <Label for="delegacionSelect">Delegación</Label>
            <Select
              closeMenuOnSelect={false}
              options={formationCenter}
              isMulti
              components={{ ClearIndicator }}
              onChange={handleFormationCenter} // Manejar el cambio de selección
              placeholder="Seleccionar..."
            />
          </FormGroup>
        </Col>

        {/* Grupo Select */}
        <Col md="4">
          <FormGroup>
            <Label for="delegacionSelect">Grupo</Label>
            <Select
              closeMenuOnSelect={false}
              options={groupList}
              isMulti
              onChange={handleGroup}
              components={{ ClearIndicator }}
              placeholder="Seleccionar..."
            />
          </FormGroup>
        </Col>
      </Row>
    
      <Row className="align-items-center">
        {/* Centro Select */}
        <Col md="4">
        <FormGroup><Label for="emailGroup">Grupo email</Label>
            <Input type="select" name="emailGroup" id="centroSelect" onChange={handleEmailGroupChange}>
              <option value="0">Email personal</option>
              <option value="1">Email centro</option>
              <option value="2">Email tutor 1</option>
              <option value="3">Email tutor 2</option>

            </Input></FormGroup>
            
       
        </Col>
        <Col className="mt-3" md="2">
          <Button onClick={handleOnClick} disabled={isLoading}>{!isLoading ? 'Filtrar' : 'Buscando'}</Button>
        </Col>
        </Row>

    </>
  );
};

FiltrarAlumnos.propTypes = {
  setAlumnos: PropTypes.any,
  setIsLoading: PropTypes.any,
  setEmailGroup: PropTypes.any,
  isLoading: PropTypes.any,
  setSelectedEmails: PropTypes.any
}
export default FiltrarAlumnos;
