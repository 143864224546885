import { Col } from 'reactstrap'
import ComponentCard from '../../layouts/containers/ComponentCard';
import FormularioUsuarios from '../../components/users/FormularioUsuarios'


const ListadoUsuarios = () => {

    return (
        <>
            <Col md="12">
                <ComponentCard title="Listado de alumnos" hasButton>
                   
                        <FormularioUsuarios />
                
                </ComponentCard>
            </Col>


        </>
    )
}
export default ListadoUsuarios