import PropTypes from 'prop-types'
import '../../../assets/styles/login/login.css'

const HeadquarterInput = (props) => {
    return (
        <label htmlFor={`${props.headquarter}-input`}>
            {props.headquarter === 'CEDESCA' ? <input type="radio" name="login" id={`${props.headquarter}-input`} value={props.headquarter} onChange={props.onChange} defaultChecked /> : <input type="radio" name="login" id={`${props.headquarter}-input`} value={props.headquarter} onChange={props.onChange} />}
            <img alt="Logo escuela" src={props.imgUrl} width="120" />
        </label>
    )
}

HeadquarterInput.propTypes = {
    headquarter: PropTypes.string,
    imgUrl: PropTypes.string,
    onChange: PropTypes.func
}
export default HeadquarterInput