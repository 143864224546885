import { Col } from 'reactstrap'
import ComponentCard from '../../layouts/containers/ComponentCard'
import TablaLogs from '../../components/logs/TablaLogs'

const Logs = () => {
    return (
        <Col md="12">
            <ComponentCard title="Registro de actividad" hasButton={false} className='clearfix'>
                <TablaLogs />
            </ComponentCard>
        </Col >
    )
}

export default Logs