import * as Icon from 'react-feather';

export const SIDEBAR_OPTIONS = {
  ALUMNOS: 1,
  SERVICIO_INTERCAMBIO: 2,
  ACTAS: 3,
  BOLETINES: 4,
  MATRICULAS: 5,
  LOGS: 6,
  CALCULO_NOTAS: 7,
  SUPLANTACION_IDENTIDAD: 8,
  IMPORTAR_NOTAS_D2L: 10
};

const SidebarData = [
  {
    title: 'Alumnos',
    href: '/alumnos',
    id: 1,
    suffix: '',
    suffixColor: 'bg-info text-dark-white',
    icon: <Icon.User />,
    collapisble: true,
  },
  {
    title: 'Servicio de intercambio',
    href: '/servicio-de-intercambio',
    id: 2,
    suffix: '',
    suffixColor: 'bg-info text-dark-white',
    icon: <Icon.Upload />,
    collapisble: true,
  },
  {
    title: 'Actas',
    href: '/actas',
    id: 3,
    suffix: '',
    suffixColor: 'bg-info text-dark-white',
    icon: <Icon.Archive />,
    collapisble: true,
  },
  {
    title: 'Boletines',
    href: '/boletines',
    id: 4,
    suffix: '',
    suffixColor: 'bg-info text-dark-white',
    icon: <Icon.FileText />,
    collapisble: true,
  },
  {
    title: 'Matrículas',
    href: '/solicitudes-matricula',
    id: 5,
    suffix: '',
    suffixColor: 'bg-info text-dark-white',
    icon: <Icon.Briefcase />,
    collapisble: true,
  },
  {
    title: 'Cálculo de notas',
    href: '/calculo-notas',
    id: 7,
    suffix: '',
    suffixColor: 'bg-info text-dark-white',
    icon: <Icon.Edit3 />,
    collapisble: true,
  },
  {
    title: 'Logs',
    href: '/logs',
    id: 6,
    suffix: '',
    suffixColor: 'bg-info text-dark-white',
    icon: <Icon.Activity />,
    collapisble: true,
  },
  {
    title: 'Envío de mails',
    href: '/envio-mails',
    id: 8,
    suffix: '',
    suffixColor: 'bg-info text-dark-white',
    icon: <Icon.Mail />,
    collapisble: true,
  },
  {
    title: 'Suplantación de identidad',
    href: '/suplantacion-identidad',
    id: SIDEBAR_OPTIONS.SUPLANTACION_IDENTIDAD,
    suffix: '',
    suffixColor: 'bg-info text-dark-white',
    icon: <Icon.Users />,
    collapisble: true,
  },
  {
    title: 'Importar notas D2L',
    href: '/importar-notas',
    id: SIDEBAR_OPTIONS.IMPORTAR_NOTAS_D2L,
    suffix: '',
    suffixColor: 'bg-info text-dark-white',
    icon: <Icon.Layers />,
    collapisble: true,
  },
];

export default SidebarData;
