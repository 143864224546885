import HttpService from './httpService';

class OtrosApiService extends HttpService {
  constructor(baseUrl, generalCatch) {
    super(baseUrl, generalCatch);
  }

  async consultaEmpresas({ headquarter }) {
    return this.get('consultaEmpresas', { params: { headquarter } });
  }

  async mostrarLogs() {
    return this.get('mostrarLogs');
  }

  async getOrgUnit() {
    return this.get('getOrgUnit');
  }

  async getPeticionesPendientes(params) {
    return this.get('getPeticionesPendientes', { params });
  }
}

const otrosApiService = new OtrosApiService(process.env.REACT_APP_NODE_API);
export default otrosApiService;
