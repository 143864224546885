import { useEffect, useState, useContext } from 'react';
import {  useNavigate,useParams } from 'react-router-dom';
import { Col } from 'reactstrap';
import PropTypes from 'prop-types';
import ComponentCard from '../../layouts/containers/ComponentCard';
import ResumenSol from '../../components/matriculas/solicitudes/ResumenSol';
import { SearchMatContext } from '../../context/searchMatContext';
import matriculacionApiService from '../../services/http/matriculacionApiService';

const Revision = (props) => {
  const { matriculaId: id, empresaId: headquarter } = useParams();

  const [idUsuario, setIdUsuario] = useState('');
  const [dataCharged, setDataCharged] = useState(false);
  const navigate = useNavigate()
  const { saveSearches, searchMatState } = useContext(SearchMatContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dataMatricula = await matriculacionApiService.getMatriculaById(id, { headquarter });

        if (localStorage.getItem('enrollment')) localStorage.removeItem('enrollment');
        localStorage.setItem('enrollment', JSON.stringify(dataMatricula));
        setDataCharged(true);
      } catch (error) {
        setDataCharged(false);
      }
    };

    fetchData();
  }, []);

  if (!dataCharged) return null;

  
  const handleClick = () => {
      
      const search = searchMatState
 
      search.newSearch = false
       saveSearches(search)
       navigate('/solicitudes-matricula')
     };

  return (
    <>
      <Col md="12">
        <ComponentCard
          title={`Revisión de solicitud - ${headquarter} - ${id} - Creado por  - ${idUsuario}`}
          hasButton={false} handleClick={handleClick} dropdown
        >
          <ResumenSol tipo={props.tipo} idMatricula={id} setIdUsuario={setIdUsuario} handleOnClick={handleClick}/>
        </ComponentCard>
      </Col>
    </>
  );
};

Revision.propTypes = {
  tipo: PropTypes.number,
};

export default Revision;
