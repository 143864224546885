import React, { useState, useEffect, useContext, useRef} from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import * as Icon from 'react-feather';
import PropTypes from 'prop-types';
import { Button, FormGroup, Input, Label, FormText, TabPane, Col, Row } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AuthContext } from '../../context/authContext';
import alumnosApiService from "../../services/http/alumnosApiService"

const INITIAL_VALUES = {
    nombre: '',
    nacionalidad: '',
    email: '',
    dni: '',
    cuenta1: '',
    cuenta2: '',
    cuenta3: '',
    cuenta4: '',
    iban: '',
    swift: '',
    sepaFirmado: 0, // Nuevo campo para la firma del mandato SEPA
    fechaActual: '' 
}

const Sepa = ({ prevActiveTab }) => {
    const formikRef = useRef(null);
    const [iban, setIban] = useState('');
    const [ibanError, setIbanError] = useState('');
    const { userOU } = useContext( AuthContext );
    const [initialValues, setInitialValues] = useState(INITIAL_VALUES);

    const { id, empresaId } = JSON.parse(localStorage.getItem('student'));

    const formatBankAccount = (values) => {
        const { cuenta1, cuenta2, cuenta3, cuenta4 } = values;
        if (cuenta1 !== null){
            return `${cuenta1}${cuenta2}${cuenta3}${cuenta4}`;
        }
        return '';
    };

    const saveData = async (values, setSubmitting) => {
      try {
        const dataToSend = {
          id,
          empresaId,
          ...values,
        };

        const data = await alumnosApiService.actualizarSepa({
          headquarter: empresaId,
          data: dataToSend,
        });

        console.log('Success:', data);
        setIbanError('');
      } catch (error) {
        setIbanError('No se ha podido actualizar el SEPA.');
      } finally {
        setSubmitting(false);
      }
    };

    useEffect(() => {
      const fetchInitialSepa = async () => {
        try {
          const actualData = await alumnosApiService.verSepa({ id, headquarter: empresaId });

          setIban(actualData[0].iban);
          setInitialValues({
            nombre: actualData[0].CNBRBCO,
            nacionalidad: actualData[0].nacionalidad_titular,
            email: actualData[0].titular_email,
            dni: actualData[0].DniTitular,
            cuenta1: actualData[0].CENTIDAD,
            cuenta2: actualData[0].CAGENCIA,
            cuenta3: actualData[0].CCTRLBCO,
            cuenta4: actualData[0].CCUENTA,
            iban: actualData[0].iban,
            swift: actualData[0].swift,
            sepaFirmado: actualData[0].f,
            fechaActual: actualData[0].fecha_mandato,
          });
        } catch (error) {
          setIban('');
          setInitialValues(INITIAL_VALUES);
        }
      };

      fetchInitialSepa();
    }, []);

    // Efecto para guardar al cambiar de pestaña y resetear el dirty de formik.
    useEffect(() => {
        const handleTabChange = async () => {
            if (prevActiveTab === "5") {
                if (formikRef.current.dirty) await saveData(formikRef.current.values, formikRef.current.setSubmitting)
            } else {
                formikRef.current.resetForm({ values: formikRef.current.values })
            }
        }

        handleTabChange()
    }, [prevActiveTab])

    function modulo97(numero) {
        let checksum = numero.slice(0, 2)
        let fragment;
        for (let offset = 2; offset < numero.length; offset += 7) {
            fragment = checksum + numero.substring(offset, offset + 7);
            checksum = parseInt(fragment, 10) % 97;
        }
        return checksum;
    }

    const validateIBAN = (ccc) => {
        const pais = "ES";
        const codigoPaisNumerico = "142800"; // E=14, S=28 y dos ceros adicionales
    
       
    const numeroCompleto = ccc + codigoPaisNumerico;
    const modulo = modulo97(numeroCompleto);
    const digitosControl = String(98 - modulo).padStart(2, '0');
    
        const ibann = pais + digitosControl + ccc;
       
        return ibann;
    };

    const handleVerify = async (values, setFieldValue) => {
      const formattedIban = formatBankAccount(values);

      const isValid = validateIBAN(formattedIban);
      setFieldValue('iban', isValid);
      setIban(isValid);
      
      try {
        const actualData = await alumnosApiService.validarSepa({
          iban: isValid,
          headquarter: empresaId,
        });

        if (actualData.length === 0) {
          setIbanError('El IBAN es incorrecto');
        } else {
          setInitialValues({
            nombre: values.nombre,
            nacionalidad: values.nacionalidad,
            email: values.email,
            dni: values.dni,
            cuenta1: values.cuenta1,
            cuenta2: values.cuenta2,
            cuenta3: values.cuenta3,
            cuenta4: values.cuenta4,
            iban: formattedIban,
            swift: actualData[0].swift,
            sepaFirmado: values.sepaFirmado,
            fechaActual: values.fechaActual,
          });
          setIbanError('');
        }
      } catch (error) {
        setIbanError('El IBAN no se ha podido verificar');
      }
    };

    const handleChange = (setFieldValue, field, value) => {
        setFieldValue(field, value);
        setIban('');
        setIbanError('Debe verificar el IBAN después de hacer cambios en la cuenta.');
    };

    const getCurrentDateFormatted = () => {
        const date = new Date();
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    return (
        <TabPane tabId="5">
            <Formik
                innerRef={formikRef}
                enableReinitialize
                initialValues={initialValues}
                validationSchema={Yup.object({
                    nombre: Yup.string().required('Obligatorio').nullable(),
                    nacionalidad: Yup.string().required('Obligatorio').nullable(),
                    email: Yup.string().email('Inválido').required('Obligatorio').nullable(),
                    dni: Yup.string().required('Obligatorio').matches(/^(\d{8}[A-Za-z]|[XYZ]\d{7}[A-Za-z])$/, 'Inválido').nullable(),
                    cuenta1: Yup.string().required('Obligatorio').length(4, 'Debe tener 4 caracteres').nullable(),
                    cuenta2: Yup.string().required('Obligatorio').length(4, 'Debe tener 4 caracteres').nullable(),
                    cuenta3: Yup.string().required('Obligatorio').length(2, 'Debe tener 2 caracteres').nullable(),
                    cuenta4: Yup.string().required('Obligatorio').length(10, 'Debe tener al menos 10 caracteres').nullable(),
                })}
                onSubmit={(values, { setSubmitting }) => saveData(values, setSubmitting)}
            >
                {({ values, isSubmitting, setFieldValue }) => (
                    <Form>
                        <Row>
                            <Col md="2">
                                <FormGroup>
                                    <Label for="nombre">Nombre y apellidos<span className='text-danger'>*</span></Label>
                                    <Field name="nombre">
                                        {({ field }) => (
                                            <Input type="text" {...field} id="nombre" disabled={userOU.centro === 'ORIENTACIÓN'}/>
                                        )}
                                    </Field>
                                    <ErrorMessage name="nombre" component={FormText} color="danger" />
                                </FormGroup>
                            </Col>
                            <Col md="2">
                                <FormGroup>
                                    <Label for="nacionalidad">Nacionalidad<span className='text-danger'>*</span></Label>
                                    <Field name="nacionalidad">
                                        {({ field }) => (
                                            <Input type="text" {...field} id="nacionalidad" disabled={userOU.centro === 'ORIENTACIÓN'}/>
                                        )}
                                    </Field>
                                    <ErrorMessage name="nacionalidad" component={FormText} color="danger" />
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                <FormGroup>
                                    <Label for="email">Email<span className='text-danger'>*</span></Label>
                                    <Field name="email">
                                        {({ field }) => (
                                            <Input type="email" {...field} id="email" disabled={userOU.centro === 'ORIENTACIÓN'}/>
                                        )}
                                    </Field>
                                    <ErrorMessage name="email" component={FormText} color="danger" />
                                </FormGroup>
                            </Col>
                            <Col md="2">
                                <FormGroup>
                                    <Label for="dni">DNI/NIE<span className='text-danger'>*</span></Label>
                                    <Field name="dni">
                                        {({ field }) => (
                                            <Input type="text" {...field} id="dni" disabled={userOU.centro === 'ORIENTACIÓN'}/>
                                        )}
                                    </Field>
                                    <ErrorMessage name="dni" component={FormText} color="danger" />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md="1">
                                <FormGroup>
                                    <Label for="cuenta1">Número</Label>
                                    <Field name="cuenta1">
                                        {({ field }) => (
                                            <Input
                                                type="text"
                                                {...field}
                                                disabled={userOU.centro === 'ORIENTACIÓN'}
                                                id="cuenta1"
                                                maxLength="4"
                                                onChange={(e) => handleChange(setFieldValue, 'cuenta1', e.target.value)}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage name="cuenta1" component={FormText} color="danger" />
                                </FormGroup>
                            </Col>
                            <Col md="1">
                                <FormGroup>
                                    <Label for="cuenta2">de</Label>
                                    <Field name="cuenta2">
                                        {({ field }) => (
                                            <Input
                                                type="text"
                                                {...field}
                                                id="cuenta2"
                                                maxLength="4"
                                                disabled={userOU.centro === 'ORIENTACIÓN'}
                                                onChange={(e) => handleChange(setFieldValue, 'cuenta2', e.target.value)}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage name="cuenta2" component={FormText} color="danger" />
                                </FormGroup>
                            </Col>
                            <Col md="1">
                                <FormGroup>
                                    <Label for="cuenta3">cuenta<span className='text-danger'>*</span></Label>
                                    <Field name="cuenta3">
                                        {({ field }) => (
                                            <Input
                                                type="text"
                                                {...field}
                                                disabled={userOU.centro === 'ORIENTACIÓN'}
                                                id="cuenta3"
                                                maxLength="2"
                                                onChange={(e) => handleChange(setFieldValue, 'cuenta3', e.target.value)}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage name="cuenta3" component={FormText} color="danger" />
                                </FormGroup>
                            </Col>
                            <Col md="2">
                                <FormGroup>
                                    <Label for="cuenta4" className='opacity-0'>Cuenta 4</Label>
                                    <Field name="cuenta4">
                                        {({ field }) => (
                                            <Input
                                                type="text"
                                                {...field}
                                                disabled={userOU.centro === 'ORIENTACIÓN'}
                                                id="cuenta4"
                                                maxLength="10"
                                                onChange={(e) => handleChange(setFieldValue, 'cuenta4', e.target.value)}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage name="cuenta4" component={FormText} color="danger" />
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <FormGroup>
                                    <Label for="iban">Número IBAN</Label>
                                    <Field name="iban">
                                        {({ field }) => (
                                            <Input type="text" {...field} id="iban" value={iban} disabled />
                                        )}
                                    </Field>
                                    <ErrorMessage name="iban" component={FormText} color="danger" />
                                </FormGroup>
                            </Col>
                            <Col md="2">
                                <FormGroup>
                                    <Label for="swift">Swift</Label>
                                    <Field name="swift">
                                        {({ field }) => (
                                            <Input type="text" {...field}  id="swift" disabled />
                                        )}
                                    </Field>
                                    <ErrorMessage name="swift" component={FormText} color="danger" />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md="3">
                                <FormGroup>
                                    <Label for="sepa">¿Firmado el mandato SEPA?</Label>
                                    <Field name="sepaFirmado">
                                        {({ field }) => (
                                            <Input
                                                type="checkbox"
                                                {...field}
                                                id="sepa"
                                                checked={field.value === 1}
                                                disabled={userOU.centro === 'ORIENTACIÓN'}
                                                onChange={() => {
                                                    const newValue = field.value === 1 ? 0 : 1;
                                                    setFieldValue('sepaFirmado', newValue);
                                                    setFieldValue('fechaActual', newValue === 1 ? getCurrentDateFormatted() : '');
                                                }}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage name="sepaFirmado" component={FormText} color="danger" />
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <FormGroup>
                                    <Label for="fechaActual">Fecha Actual</Label>
                                    <Input type="text" id="fechaActual" value={values.fechaActual === '00-00-0000' ? '' : values.fechaActual} disabled />
                                </FormGroup>
                            </Col>
                        </Row>

                        {ibanError !== '' && (
                            <Row>
                                <Col md="12">
                                    <p style={{ color: 'red' }}>{ibanError}</p>
                                </Col>
                            </Row>
                        )}

                        <Row>

                            <Col md="1">
                            {userOU.centro !== 'ORIENTACIÓN' ? <Button
                                    type="button"
                                    color="primary"
                                    onClick={() => handleVerify(values, setFieldValue)}
                                >
                                    Verificar
                                </Button> : ''}
                                
                            </Col>
                            <Col md="2">
                            {userOU.centro !== 'ORIENTACIÓN' ? <Button type="submit" color="success" disabled={isSubmitting || ibanError !== ''}>
                                    <Icon.Save />
                                </Button> : ''}
                                
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
        </TabPane>
    );
};

Sepa.propTypes = {
    prevActiveTab: PropTypes.string
};

export default Sepa;
