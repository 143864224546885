import { useState } from 'react'
import PropTypes from 'prop-types'
import { ModalBody, ModalFooter, Modal, Button } from 'reactstrap'
import phpApiService from '../../services/http/phpApiService'


const ModalOption = (props) => {
    const [modalState, setModalState] = useState(true)

    const handleModalState = () => {
      if (modalState === true) {
        setModalState(false);
      } else {
        setModalState(true);
      }
      if (props.sent) props.sent(false);
    };

    const handleModalAccept = async () => {
      props.load(true);
      setModalState(false);
      if (!props.boletin) {
        try {
          await phpApiService.generarDocumento({
            alumnoId: props.studentId,
            empresa: props.empresa,
            cursoCode: props.ciclo.codigo,
            tipoDoc: 'SENDBOLNOT',
            idiomaCode: '1',
          });
          props.load(false);
        } catch (error) {
          props.load(false);
        }
      } else {
        setModalState(false);
        if (props.sendData) await props.sendData();
      }
      if (props.sent) props.sent(false);
    };

    return (
        <Modal isOpen={modalState} centered>
            <ModalBody>
                <h4 className="text-center mt-3">{props.header}</h4>
            </ModalBody>
            <ModalFooter>
                <Button color='success' onClick={handleModalAccept}>
                    Aceptar
                </Button>
                <Button color='danger' onClick={handleModalState}>
                    Cerrar
                </Button>
            </ModalFooter>
        </Modal>
    )
}
ModalOption.propTypes = {
    header: PropTypes.any,
    load: PropTypes.any,
    studentId: PropTypes.any,
    ciclo: PropTypes.any,
    empresa: PropTypes.any,
    sendData: PropTypes.any,
    boletin: PropTypes.bool,
    sent: PropTypes.any
};
export default ModalOption