import { Col } from 'reactstrap'
import ComponentCard from '../../layouts/containers/ComponentCard';
import FormularioBoletines from '../../components/boletines/FormularioBoletines';

const Boletines = () => {
    return (
        <>
            <Col md="12">
                <ComponentCard title="Boletín de notas para menores" hasButton={false}>
                    <FormularioBoletines />
                </ComponentCard>
            </Col>
        </>
    )
}

export default Boletines