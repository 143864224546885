
import { useState, useEffect } from 'react'
import { Col, Form, Row, Input, Button, Label, Spinner} from 'reactstrap'
import OptionsForm from '../../layouts/forms/OptionsForm'
import Loader from '../../layouts/loader/Loader'
import TablaTutores from './TablaTutores'
import ModalOption from '../../layouts/modals/ModalOption'
import ModalClickable from '../../layouts/modals/ModalClickable'
import phpApiService from '../../services/http/phpApiService'

const FormularioBoletines = () => {

    const [isShown, setIsShown] = useState(false)
    const [isLoadingSpinner, setIsLoadingSpinner] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isDownloaded, setIsDownloaded] = useState(false)
    const [isError, setIsError] = useState(false)
    const [selected, setSelected] = useState([])
    const [tutores, setTutores] = useState('')
    const [dataApi, setDataApi] = useState('')
    const [headquarter, setHeadquarter] = useState('')
    const [courseYear, setCourseYear] = useState('')
    const [course, setCourse] = useState('')
    const [isEmpty, setIsEmpty] = useState(false)
    const [type, setType] = useState('')


    useEffect(() => {
      const fetchData = async () => {
        try {
          const dataOpcionesSII = await phpApiService.cargarOpcionesSII();
          setDataApi(dataOpcionesSII);
          setHeadquarter(dataOpcionesSII.centre[0].codigo);
          setCourseYear(dataOpcionesSII.curs[0].codigo);
          setCourse(dataOpcionesSII.grau[0].codigo);
          setType(dataOpcionesSII.modalitat[0].codigo);
        } catch (error) {
          setDataApi('');
          setHeadquarter('');
          setCourseYear('');
          setCourse('');
          setType('');
        }
      };

      fetchData();
    }, []);

    const handleHeadquarter = (e) => {
        setHeadquarter(e.target.value)
    }
    const handleCourseYear = (e) => {
        setCourseYear(e.target.value)
    }
    const handleCourse = (e) => {
        setCourse(e.target.value)
    }
    const handleType = (e) => {
        setType(e.target.value)
    }

   const sendData = async () => {
     setIsLoading(true);
     const idAlumnos = [];
     for (let i = 0; i < selected.length; i++) {
       const objeto = selected[i];
       if (objeto.id !== undefined) {
         idAlumnos.push(objeto.id);
       }
     }
     const formData = new FormData();

     formData.append('idAlumnos', '[7777,7777]');

     try {
       const dataEnvioBolnot = await phpApiService.generarEnvioBolnot({
         centre: headquarter,
         curso: courseYear,
         grau: course,
         modalitat: type,
       });

       if (!dataEnvioBolnot.itWorked) {
         setIsDownloaded(true);
       } else {
         setIsError(true);
       }
       setIsLoading(false);
     } catch (error) {
       setIsLoading(false);
     }
   };

    console.log(isLoading)
    const onCheckboxChange = (element) => {
        const isSelected = selected.some(item => item.id === element.id);
        
        if (isSelected) {
          // If element is already selected, remove it from the array
          setSelected(prevSelected => prevSelected.filter(item => item.id !== element.id));
        } else {
          // If element is not selected, add it to the array
          setSelected(prevSelected => [...prevSelected, element]);
        }
      };

    const loadData = async () => {
      setIsLoadingSpinner(true);

      try {
        const dataTutores = await phpApiService.buscarTutores({
          centre: headquarter,
          curso: courseYear,
          grau: course,
          modalitat: type,
        });

        setTutores(dataTutores);
        dataTutores.forEach((element) => {
          element.actions = (
            <>
              <Input type="checkbox" onChange={() => onCheckboxChange(element)}></Input>
            </>
          );
        });
      } catch (error) {
        setTutores('');
      } finally {
        setIsLoadingSpinner(false);
      }
    };

    const showModal = async () => {
        await loadData()
        if(selected.length > 0 ) {
            setIsShown(!isShown)
        }else{
            setIsEmpty(!isEmpty)
        }
        
    }

    return (
        <>

            {isDownloaded ? <ModalClickable color="primary" header="Petición recibida correctamente. El archivo se enviará a todos los tutores." /> : ''}
 
            {isError ? <ModalClickable header="Ha surgido un error. Consulte con el administrador." /> : ''}        
            {isShown ? <ModalOption sendData={sendData} boletin header="¿Desea enviar el boletín a todos los tutores de alumnos menores?" load={setIsLoading}  /> : ''}
            {isEmpty ? <ModalClickable color="primary" header="No ha seleccionado tutores." /> : ''}
            <Form >
                {dataApi !== '' ? <>
                    <Row className="mb-3">
                        <Col sm className="mt-2 mt-md-2">
                            <Label for="centros">
                                Centro
                            </Label>
                            <Input type="select" name="centros" id="centros" onChange={handleHeadquarter}>
                                <OptionsForm datos={dataApi.centre} />
                            </Input>
                        </Col>
                        <Col sm className="mt-2 mt-md-2">
                            <Label for="curso">
                                Curso
                            </Label>
                            <Input type="select" name="curso" id="curso" onChange={handleCourseYear}>
                                <OptionsForm datos={dataApi.curs} />
                            </Input>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col sm className="mt-2 mt-md-2">
                            <Label for="modalidad">
                                Modalidad
                            </Label>
                            <Input type="select" name="modalidad" id="modalidad" onChange={handleType}>
                                <OptionsForm datos={dataApi.modalitat} />
                            </Input>
                        </Col>
                        <Col sm className="mt-2 mt-md-2">
                            <Label for="grado">
                                Grado
                            </Label>
                            <Input type="select" name="grado" id="grado" onChange={handleCourse}>
                                <OptionsForm datos={dataApi.grau} />
                            </Input>
                        </Col>

                    </Row>
                    <Row className="mt-0 mt-md-2">

                        <Col className="text-center">
                            <Button className={isLoadingSpinner ? "mt-2 mt-md-0 w-50 text-center disabled" : "mt-2 mt-md-0 w-50 text-center"} onClick={loadData}>{isLoadingSpinner ? <>Cargando <Spinner color="primary" style={{ height: '15px', width: '15px' }} /> </> : 'Buscar'}</Button>
                        </Col>
                    </Row>
                </> : <Loader />}
            </Form >
            {tutores !== '' ? <TablaTutores data={tutores} />: ''}
            {tutores !== '' ? <Button onClick={showModal} className={isLoadingSpinner ? "disabled" : ""}>Enviar</Button> : ''}
            
        </>
    )

}


export default FormularioBoletines