import { React, useState, createContext} from 'react';
import PropTypes from 'prop-types';

export const SearchMatContext = createContext('');

const initialState = {
  nameSearch: '',
  lastnameSearch: '',
  dniSearch: '',
  emailSearch: '',
  headquarterSearch: '',
  courseSearch: '',
  stateSearch: '',
  idMatSearch: '',
  formationCenterSearch: '',
  dbSearch: 'CEDETECGROUP',
  newSearch: true,
  deudores: false,
};

export const SearchMatContextProvider = (props) => {
  const [searchMatState, setSearchMatState] = useState(initialState);

  // Function to save search data
  const saveSearches = (response) => {
    setSearchMatState(response);
  };

  const resetSearchMatContext = () => {
    setSearchMatState(initialState);
  };

  return (
    <SearchMatContext.Provider
      value={{ searchMatState, saveSearches, resetSearchMatContext }}>
      {props.children}
    </SearchMatContext.Provider>
  );
}

SearchMatContextProvider.propTypes = {
  children: PropTypes.node,
};
