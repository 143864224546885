import {useState, React} from 'react';
import { Col, Row, Button } from 'reactstrap'
import BuscarAlumnos from '../../components/envioMails/BuscarAlumnos';
import RedactarMail from '../../components/envioMails/RedactarMail';
import ComponentCard from '../../layouts/containers/ComponentCard';
import envioMailService from '../../services/http/envioEmailService';
import ModalClickable from '../../layouts/modals/ModalClickable';

const EnvioMails = () => {

    const [pageCount, setPageCount] = useState(0)
    const [alumnos, setAlumnos] = useState('')
    const [asunto, setAsunto] = useState('')
    const [cuerpo, setCuerpo] = useState('')
    const [selectedEmails, setSelectedEmails] = useState({});
    const [isSent, setIsSent] = useState(false)


    const handleOnClick = () => {
        if (pageCount === 1){
            setPageCount(0)
        }else {
            setPageCount(1)  
        }
        
    }
    const handleSend = async () => {
        const correos = Object.values(selectedEmails);
        const ccodclis = Object.keys(selectedEmails);
        const values = {
            email: 'no-reply@cedetecgroup.com',
            emails: correos,
            contenido: cuerpo,
            ccodclis,
            asunto,
        };
    
        try {
            // Marcar como enviando antes de iniciar la solicitud
            setIsSent(false);
    
            // Llamada a la API para enviar el correo de manera asíncrona
            await envioMailService.enviarCorreoMasivo(values, {
                empresaId: 'CEDESCA',
            });
    
            // Actualizar el estado una vez que el correo se ha enviado
            setIsSent(true);
        } catch (error) {
            console.error('Error al enviar el correo:', error);
            setIsSent(false);
        }
    };
    return (
        <>
    {isSent && <ModalClickable color="primary" header="Petición recibida correctamente. El email se enviará a todos los usuarios." url='/alumnos' />}
            <Col md="12">
                <ComponentCard title="Enviar emails" hasButton={false}>
                    {pageCount === 0 ? <BuscarAlumnos  setPageCount={setPageCount}  alumnos={alumnos} setAlumnos={setAlumnos} setSelectedEmails={setSelectedEmails} selectedEmails={selectedEmails}/> :
                     <RedactarMail setPageCount={setPageCount} setCuerpo={setCuerpo} setAsunto={setAsunto} asunto={asunto} cuerpo={cuerpo}/> }               
                    {alumnos !== '' && pageCount === 0 ?  <Row className="mt-3">
        <Col className="d-flex justify-content-end">
        <Button onClick={handleOnClick} disabled={Object.keys(selectedEmails).length=== 0}>Siguiente</Button>
        </Col>
      </Row>: ''}
      {pageCount === 1 ? <Row className="mt-3">
        <Col className="d-flex justify-content-end">
        <Button onClick={handleOnClick}>Anterior</Button>
        </Col>
        <Col>
        <Button onClick={handleSend} disabled={cuerpo.length === 0 || asunto.length === 0}>Enviar</Button>
        </Col>
      </Row> : '' }
                </ComponentCard>
            </Col>


        </>
    )
}
export default EnvioMails