import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types'
import { Button, Col, Collapse, Container, Row, Table, Tooltip } from 'reactstrap'
import * as Icon from 'react-feather';
import ModalLoader from '../../layouts/modals/ModalLoader';
import documentosApiService from '../../services/http/documentosApiService';

const Matricula = (props) => {
    const navigate = useNavigate()
    const {matricula} = props
    
    const [isLoading, setIsLoading] = useState(false)
    const [collapse, setCollapse] = useState(false);
    const studentStorage = JSON.parse(localStorage.getItem('student'));
    const toggle = () => { setCollapse(!collapse) }

    // Open and close tooltips
    const [isToolTipDownloadMatriculaOpened, setIsToolTipDownloadMatriculaOpened] = useState(false)

    const handleRequest = (id, headquarter) => {
        const enrollment = {
            id,
            headquarter
        };

        if (localStorage.getItem('enrollment')) {
            localStorage.removeItem('enrollment');
        }
        localStorage.setItem('enrollment', JSON.stringify(enrollment));

        const empresaLowerCase = enrollment.headquarter.toLowerCase();
        navigate(`/solicitudes-matricula/${empresaLowerCase}/${enrollment.id}/revision`);
    };

    const downloadFile = async (matriculaId, empresa) => {
      setIsLoading(true);

      try {
        const blobDocAutomat = await documentosApiService.generarDocAutomat({
          headquarter: empresa,
          matriculaId,
        });

        const blobFinal = blobDocAutomat.slice(0, blobDocAutomat.size, 'application/pdf');
        const url = URL.createObjectURL(blobFinal);
        window.open(url, '_blank');
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    return (
        <div className="mb-3">
            {isLoading && <ModalLoader header="Generando documento" />}
            <Container className="border-bottom rounded-top mb-0 px-3 py-3 pe-auto" onClick={toggle} style={{ backgroundColor: '#eef5f9', cursor: 'pointer' }}>
                <Row style={{ alignItems: 'center' }}>
                    <Col xs="1">
                        {
                            props.matricula.estado === 3
                            ? <Icon.XCircle size={20} color='red' />
                            : <Icon.Book size={20} />
                        }
                    </Col>
                    <Col xs="1">
                        <h4 className="mb-0">{ props.matricula.id }</h4>
                    </Col>
                    <Col xs="8">
                        <h4 className="mb-0">{props.matricula.concat}</h4>
                    </Col>
                    <Col xs="2" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button color={ props.matricula.estado === 3 ? 'danger' : 'primary' } onClick={ () => handleRequest(props.matricula.id, studentStorage.empresaId) }>
                            Detalles
                        </Button>
                        <Button id={`button-download-matricula-${matricula.id}`} color='transparent' onClick={async (e) => {
                            if (e.stopPropagation) e.stopPropagation();
                            await downloadFile(props.matricula.id, studentStorage.empresaId)
                        }}>
                            <Tooltip placement="top" isOpen={isToolTipDownloadMatriculaOpened} target={`button-download-matricula-${matricula.id}`} toggle={() => setIsToolTipDownloadMatriculaOpened(prev => !prev)}>
                                Matrícula
                            </Tooltip>
                            <Icon.Download size={20} className="align-text-top" id="Descarga" color="black" />
                        </Button>
                    </Col>
                </Row>
            </Container>
            <Collapse isOpen={collapse} className="border px-2 py-2">
                <Table responsive borderless>
                    <thead>
                        <tr>
                            <th>Nombre</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.matricula.actividades.map((actividad) =>
                            <tr key={actividad.codigo + actividad.horas}>
                                
                                <td style={{ width: '100%' }}>{actividad.nombre}</td>
                            </tr>
                        )}
                    </tbody>
                </Table>


            </Collapse>
        </div >
    )
}

Matricula.propTypes = {
    matricula: PropTypes.any
};

export default Matricula