import React, { useContext } from 'react';
import { Nav } from 'reactstrap';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SimpleBar from 'simplebar-react';
import SidebarData, { SIDEBAR_OPTIONS } from '../sidebardata/SidebarData';
import Logo from '../../logo/Logo';
import NavItemContainer from './NavItemContainer';
import NavSubMenu from './NavSubMenu';
import { AuthContext } from '../../../context/authContext';

const Sidebar = () => {

  const location = useLocation();
  const currentURL = location.pathname.split('/').slice(0, -1).join('/');

  //const [collapsed, setCollapsed] = useState(null);
  // const toggle = (index) => {
  //   setCollapsed(collapsed === index ? null : index);
  // };

  const activeBg = useSelector((state) => state.customizer.sidebarBg);
  const isFixed = useSelector((state) => state.customizer.isSidebarFixed);
  const { userOU } = useContext( AuthContext );


  return (
    <div className={`sidebarBox shadow bg-${activeBg} ${isFixed ? 'fixedSidebar' : ''}`}>
      <SimpleBar style={{ height: '100%' }}>
        {/********Logo*******/}
        <div className="d-flex p-3 align-items-center">
          <Logo />
        </div>
        {/********Sidebar Content*******/}
        <div className="p-3 pt-1 mt-2">
          <Nav vertical className={activeBg === 'white' ? '' : 'lightText'}>
            {
              SidebarData.map((navi) => {

                if (navi.caption) {
                  return (
                    <div className="navCaption fw-bold mt-4" key={navi.caption}>
                      {navi.caption}
                    </div>
                  );
                }

                if (navi.children) {
                  return (
                    <NavSubMenu
                      key={navi.id}
                      icon={navi.icon}
                      title={navi.title}
                      items={navi.children}
                      suffix={navi.suffix}
                      suffixColor={navi.suffixColor}
                      // toggle={() => toggle(navi.id)}
                      // collapsed={collapsed === navi.id}
                      isUrl={currentURL === navi.href}
                    />
                  );
                }

                // Uusario con rol Administrador/IT
                // Lo ve todo
                if( userOU.idCedesca === 0 && userOU.idCntec === 0 ) {
                  return (
                    <NavItemContainer
                      key={navi.id}
                      id={navi.id}
                      //toggle={() => toggle(navi.id)}
                      className={location.pathname === navi.href ? 'activeLink' : ''}
                      to={navi.href}
                      title={navi.title}
                      suffix={navi.suffix}
                      suffixColor={navi.suffixColor}
                      icon={navi.icon}
                    />
                  );
                }
              
                // Usuario con rol de Delegación
                // Solo "Alumnos" y "Matrículas" - de sus alumnos
                if(
                  (userOU.idCedesca !== 0 && userOU.idCedesca !== -1 && userOU.idCedesca !== -2 && 
                  userOU.idCntec !== 0 && userOU.idCntec !== -1 && userOU.idCntec !== -2) &&
                  (navi.id === 1 || navi.id === 5)
                ) {
                  return (
                    <NavItemContainer
                      key={navi.id}
                      id={navi.id}
                      //toggle={() => toggle(navi.id)}
                      className={location.pathname === navi.href ? 'activeLink' : ''}
                      to={navi.href}
                      title={navi.title}
                      suffix={navi.suffix}
                      suffixColor={navi.suffixColor}
                      icon={navi.icon}
                    />
                  );
                }

                // Usuario con rol de Sede
                // Lo ve todo menos "Logs" y "Cálculo de notas"
                if( (userOU.idCedesca === -1 && userOU.idCntec === -1) && navi.id !== 6 && navi.id !== 7 && navi.id !== SIDEBAR_OPTIONS.SUPLANTACION_IDENTIDAD) {
                  return (
                    <NavItemContainer
                      key={navi.id}
                      id={navi.id}
                      //toggle={() => toggle(navi.id)}
                      className={location.pathname === navi.href ? 'activeLink' : ''}
                      to={navi.href}
                      title={navi.title}
                      suffix={navi.suffix}
                      suffixColor={navi.suffixColor}
                      icon={navi.icon}
                    />
                  );
                }

                // Usuario con rol de Orientación
                // Solo "Alumnos" y "Matrículas" - de sus alumnos
                if(
                  (userOU.idCedesca === -2 && userOU.idCedesca === -2) &&
                  (navi.id === 1 || navi.id === 5)
                ) {
                  return (
                    <NavItemContainer
                      key={navi.id}
                      id={navi.id}
                      //toggle={() => toggle(navi.id)}
                      className={location.pathname === navi.href ? 'activeLink' : ''}
                      to={navi.href}
                      title={navi.title}
                      suffix={navi.suffix}
                      suffixColor={navi.suffixColor}
                      icon={navi.icon}
                    />
                  );
                }

                // Usuario con rol de Secretaría
                // Lo ve todo menos "Logs" y "Cálculo de notas"
                if( (userOU.idCedesca === -3 && userOU.idCntec === -3) && navi.id !== 6 && navi.id !== 7 && navi.id !== SIDEBAR_OPTIONS.SUPLANTACION_IDENTIDAD) {
                  return (
                    <NavItemContainer
                      key={navi.id}
                      id={navi.id}
                      //toggle={() => toggle(navi.id)}
                      className={location.pathname === navi.href ? 'activeLink' : ''}
                      to={navi.href}
                      title={navi.title}
                      suffix={navi.suffix}
                      suffixColor={navi.suffixColor}
                      icon={navi.icon}
                    />
                  );
                }

                // Si aún no se ha obtenido el rol, solo mostramos la página inicial "Alumnos"
                if( (userOU.idCedesca === -4 && userOU.idCntec === -4) && navi.id === 1 ) {
                  return (
                    <NavItemContainer
                      key={navi.id}
                      id={navi.id}
                      //toggle={() => toggle(navi.id)}
                      className={location.pathname === navi.href ? 'activeLink' : ''}
                      to={navi.href}
                      title={navi.title}
                      suffix={navi.suffix}
                      suffixColor={navi.suffixColor}
                      icon={navi.icon}
                    />
                  );
                }

              return(<div key={navi.id}></div>);
              
            })}
          </Nav>
        </div>
      </SimpleBar>
    </div>
  );
};

export default Sidebar;
