import PropTypes from 'prop-types'

const OptionsForm = (props) => {
    return (
        <>
            {props.datos.map((opcion) => <option key={opcion.codigo} value={opcion.codigo}>{opcion.nombre}</option>)}
        </>
    )
}

OptionsForm.propTypes = {
    datos: PropTypes.any
};

export default OptionsForm