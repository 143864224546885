import { useState } from 'react'
import PropTypes from 'prop-types'
import { ModalBody, ModalFooter, Modal, Button } from 'reactstrap'


const ModalClickable = (props) => {
    const [modalState, setModalState] = useState(true)
    const handleModalState = () => {
        if (modalState === true) {
            setModalState(false)
            if (props.url) {
                window.location.href = props.url
            }
        } else {
            setModalState(true)
        }
        if(props.function !== undefined){
            props.function(false)
        }
    }
    return (
        <Modal isOpen={modalState} centered>
            <ModalBody>
                <h4 className="text-center mt-3">{props.header}</h4>
            </ModalBody>
            <ModalFooter>
                <Button color={props.color ? props.color : 'danger'} onClick={handleModalState}>
                    Cerrar
                </Button>
            </ModalFooter>
        </Modal>
    )
}
ModalClickable.propTypes = {
    header: PropTypes.any,
    color: PropTypes.string,
    url: PropTypes.string,
    function: PropTypes.any
};
export default ModalClickable