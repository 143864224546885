import { useState, useEffect } from 'react';
import PropTypes from 'prop-types'
import { Collapse, Row, List } from 'reactstrap'
import * as Icon from 'react-feather';
import Loader from '../../layouts/loader/Loader'
import matriculacionApiService from '../../services/http/matriculacionApiService';

const DetallePeticion = (props) => {
    const student = JSON.parse(localStorage.getItem('student'))
    const [collapse, setCollapse] = useState(false);
    const [dataApi, setDataApi] = useState('')
    const toggle = () => { setCollapse(!collapse) }

    const handleRequest = (id, headquarter, email) => {
        const enrollment = {
            id,
            headquarter,
            email
        };

        window.location.href = "/solicitudes-matricula/revision"

        if (localStorage.getItem('enrollment')) {
            localStorage.removeItem('enrollment');
        }
        localStorage.setItem('enrollment', JSON.stringify(enrollment));
    };

    useEffect(() => {
      const fetchData = async () => {
        try {
          const dataMatricula = await matriculacionApiService.detalleMatricula({
            headquarter: props.headquarter,
            id: props.idEnrollment,
          });
          setDataApi(dataMatricula);
        } catch (error) {
          setDataApi('');
        }
      };

      fetchData();
    }, []);

    return (
        <>
            {dataApi === '' ? <Loader /> : <div className="mb-3">
                <div className="border-bottom rounded-top mb-0 px-2 py-2 pe-auto" onClick={toggle} style={{ backgroundColor: '#eef5f9', cursor: 'pointer' }}>
                    <Row>
                        <Icon.Mail size={20} className="mt-2 col-1" />
                        <h4 className="mt-2 col-7">{props.idEnrollment} - {dataApi[0].curso} </h4>
                        <h4 className="mt-2 col-4 float-end">{dataApi[0].estado} {dataApi[0].estado.includes('secretaria') ? <Icon.Edit size={20} onClick={() => {
                            handleRequest(props.idEnrollment, student.empresaId, dataApi[0].Email);

                        }} /> : ''}</h4>

                    </Row>

                </div>
                <Collapse isOpen={collapse} className="border px-2 py-2">
                    <List>
                        {dataApi.sort().map((value) => (
                            <li key={value.id}>{value.descripcion}</li>
                        ))}
                    </List>
                </Collapse>
            </div >}
        </>

    )
}

DetallePeticion.propTypes = {
    idEnrollment: PropTypes.any,
    headquarter: PropTypes.any
};

export default DetallePeticion