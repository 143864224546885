import {React, useState} from 'react'
import PropTypes from 'prop-types';
import FiltrarAlumnos from './FiltrarAlumnos';
import ListadoAlumnos from './ListadoAlumnos';
import Loader from '../../layouts/loader/Loader';


const BuscarAlumnos = ({alumnos, setAlumnos, setSelectedEmails, selectedEmails}) => {

    
    const [isLoading, setIsLoading] = useState(false)
    const [emailGroup, setEmailGroup] = useState(0)



    return (
        <>

<FiltrarAlumnos  setAlumnos={setAlumnos} setIsLoading={setIsLoading} isLoading={isLoading} setEmailGroup={setEmailGroup} setSelectedEmails={setSelectedEmails}/>
{!isLoading ? <ListadoAlumnos alumnos={alumnos} grupo={emailGroup} selectedEmails={selectedEmails} setSelectedEmails={setSelectedEmails}/> : <Loader />}

       
    
        </>
        
    )
}

BuscarAlumnos.propTypes = {
    alumnos: PropTypes.any,
    setAlumnos: PropTypes.any,
    setSelectedEmails: PropTypes.any,
    selectedEmails: PropTypes.any
};

export default BuscarAlumnos