import { NavLink, NavItem } from "reactstrap";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/authContext";
import filtroSolicitudesMatricula from "../../../helpers/filtroSolicitudesMatricula";

const NavItemContainer = ({
  id,
  to,
  icon,
  title,
  toggle,
  className,
  suffix,
  suffixColor,
}) => {

  const [badge, setBadge] = useState('');
  const { userOU } = useContext( AuthContext );

  useEffect(() => {

    const getFiltro = async () => {
      if( id === 5 ) { // Comprobamos que estamos en el botón de Matriculas
        
        const result = await filtroSolicitudesMatricula( userOU );
        setBadge( result.badge );
   
      }
    }

    getFiltro();

    // Establecer un intervalo para actualizar cada 15 segundos
    const intervalId = setInterval(() => {
      getFiltro();
    }, 7200000);

    return () => {
      clearInterval(intervalId); // Limpia el intervalo al desmontar el componente
    };

  },[ userOU ]);

  return (
    <NavItem onClick={toggle} className={className}>
      <NavLink tag={Link} to={to} className="gap-3">
        <span className="sidebarIcon">{icon}</span>
        <span className="hide-mini w-100">
          <div className="d-flex align-items-center">
            <span>{title}</span>
            {

              badge !== 0 && (<span className={`badge ms-auto ${suffixColor}`}>{ suffix !== '' ? suffix : badge }</span>) 
            }
          </div>
        </span>
      </NavLink>
    </NavItem>
  );
};

NavItemContainer.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  to: PropTypes.string,
  icon: PropTypes.node,
  toggle: PropTypes.func,
  className: PropTypes.string,
  suffix: PropTypes.any,
  suffixColor: PropTypes.string,
};

export default NavItemContainer;
