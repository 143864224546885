import {React, useState} from 'react'
import {Col, Row, Input} from 'reactstrap'
import PropTypes from 'prop-types';
import MyEditor from '../../layouts/myEditor/MyEditor'

const RedactarMail = ({setAsunto, setCuerpo, asunto,cuerpo}) => {
  const [subjectTouched, setSubjectTouched] = useState(false);
  const [bodyTouched, setBodyTouched] = useState(false);

    const handleSubject = (e) => {
        setAsunto(e.target.value)
    }

    const handleBlur = () => {
      setSubjectTouched(true);
  };

  const handleBlurBody = () => {
    setBodyTouched(true);
};

    return (
        <>
       <Row>
            <Col>
                  <label htmlFor="asunto" className="form-label">
                    Asunto <span className="text-danger tiny">*</span>
                  </label>
                  <Input
                    type="text"
                    name="asunto"
                    id="asunti"
                    placeholder="Escriba el asunto del mail"
                    onChange={handleSubject}
                    onBlur={handleBlur} 
                    className={subjectTouched &&  asunto.length === 0 ? 'border border-danger error' : ''}

                  />
                  {subjectTouched && asunto.length === 0 && (
                <span className="text-danger tiny">Campo obligatorio</span>
              )}
            </Col>
        </Row>
        <Row className='mt-4'>
            <Col>
                  <label htmlFor="asunto" className="form-label">
                    Cuerpo <span className="text-danger tiny">*</span>
                  </label>
                 <MyEditor content={cuerpo} setContent={setCuerpo} handleBlurBody={handleBlurBody} blurBody={bodyTouched}/>
                 {bodyTouched && cuerpo.length === 0 && (
                <span className="text-danger tiny">Campo obligatorio</span>
              )}
            </Col>
        </Row> 
        </>
    )
}

RedactarMail.propTypes = {
    asunto: PropTypes.any,
    setAsunto: PropTypes.any,
    setCuerpo: PropTypes.any,
    cuerpo: PropTypes.any,
};


export default RedactarMail