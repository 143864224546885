import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';

const MyEditor = ({content, setContent, handleBlurBody, blurBody}) => {
  

  const handleEditorChange = (text) => {
    setContent(text); // Actualiza el contenido en el estado
  };

  return (
    <>
    {content !== undefined ?<div className={blurBody && content.length === 0 ? 'border border-danger error' : ''}>
      <Editor
        
        initialValue=""
        apiKey = 'faecrmatsv4p2c7kteiytqqvq3cct7qqsdfidv9gi4v5l22j'
       
        init={{
          branding: false,
          height: 500,
          menubar: true,
          plugins: [
            'table advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount'
          ],
          toolbar: 'undo redo | styleselect | bold italic | alignleft aligncenter alignright | code',
          language: 'es', // Configura el idioma a español
        }}
        onEditorChange={handleEditorChange}
        onBlur={handleBlurBody} 
      /></div> : ''}
    </>
      
    
  );
};

MyEditor.propTypes = {
  content: PropTypes.any,
  setContent: PropTypes.any,
  blurBody: PropTypes.any,
  handleBlurBody: PropTypes.any
}

export default MyEditor;
