import { useState, useEffect, useContext} from 'react'
import { useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap'
import PropTypes from 'prop-types'
import * as Icon from 'react-feather';
import ComponentCard from '../../layouts/containers/ComponentCard';
import DetallePeticion from './DetallePeticion';
import Loader from '../../layouts/loader/Loader'
import { AuthContext } from '../../context/authContext';
import matriculacionApiService from '../../services/http/matriculacionApiService';

const PeticionMatricula = (props) => {

    const navigate = useNavigate()
    const student = JSON.parse(localStorage.getItem('student'))
    const { userOU } = useContext( AuthContext );
    const [dataApi, setDataApi] = useState('');
    const [balance, setBalance] = useState(0);

    const handleOnClick = () => {
       navigate("/matriculas/nueva")
    }

    useEffect(() => {
      const fetchData = async () => {
        const localStudent = JSON.parse(localStorage.getItem('student'));
        try {
          const dataPeticionActiva = await matriculacionApiService.peticionActiva({
            headquarter: localStudent.empresaId,
            id: localStudent.id,
          });
          setDataApi(dataPeticionActiva);
        } catch (error) {
          setDataApi('');
        }

        try {
          const dataBalanceGeneral = await matriculacionApiService.consultarBalanceGeneral({
            headquarter: localStudent.empresaId,
            id: localStudent.id,
          });
          setBalance(parseFloat(dataBalanceGeneral.Balance));
        } catch (error) {
          setBalance(0);
        }
      };

      fetchData();
    }, []);

    return (
        <ComponentCard title="Peticiones de matrícula">
            {
                dataApi === ''
                ? (<Loader />)
                : dataApi.length === 0
                    ? (
                        <>
                            {
                                props.missingData || props.missingDataT || props.missingDataE
                                    ? (<a className='text-danger'>
                                        Los datos del alumno (
                                        {[
                                          props.missingData && 'personales',
                                          props.missingDataE && 'escolares',
                                          props.missingDataT && 'tutores'
                                        ]
                                          .filter(Boolean) // Filtrar valores no válidos (undefined o false)
                                          .join(', ') // Unir con comas
                                          .replace(/,([^,]*)$/, ' y$1')} {/* Reemplazar la última coma con 'y' */}
                                        ) no son correctos.
                                      </a>
                                      )
                                    : balance < 0
                                        ? (<a className='text-danger'>El alumno tiene un balance negativo de { balance.toString() } €.</a>)
                                        : props.recibosPendientes
                                            ? (<a className='text-danger'>El alumno tiene recibos pendientes o devueltos.</a>)
                                            : (<a>Este usuario no dispone de peticiones activas.</a>)
                            }
                            {
                                userOU.centro === 'ORIENTACIÓN' ? '' :  <Button className="float-end col-1 w-auto" color="success" disabled={ props.missingData || props.missingDataT || props.missingDataE || balance < 0 || props.recibosPendientes }>
                                <a>
                                    <Icon.Plus onClick={handleOnClick} />
                                </a>
                            </Button>
                            }
                           
                        </>
                    )
                    : <DetallePeticion idEnrollment={dataApi[0].NumMatricula} headquarter={student.empresaId} />
            }
        </ComponentCard >
    )
}
PeticionMatricula.propTypes = {
    recibosPendientes: PropTypes.any,
    missingData: PropTypes.any,
    missingDataT: PropTypes.any,
    missingDataE: PropTypes.any
};
export default PeticionMatricula