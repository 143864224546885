import { useEffect, useState } from "react";
import { TabPane, Table } from "reactstrap";
import PropTypes from 'prop-types';
import Loader from "../../layouts/loader/Loader";
import documentosApiService from "../../services/http/documentosApiService";

const Contabilidad = ({ setRecibosPendientes }) => {

    // Preparamos los estados necesarios
    const [dataLoaded, setDataLoaded] = useState(false);
    const [dataApi, setDataApi] = useState([]);
    const [totalRecibos, setTotalRecibos] = useState(0);
    // Preparamos las variables necesarias
    const studentSession = JSON.parse(localStorage.getItem('student'));
    const opcionesFecha = { day: '2-digit', month: '2-digit', year: 'numeric' };

    // Peticiones a la API
    useEffect(() => {
      const fetchData = async () => {
        try {
          const dataContabilidad = await documentosApiService.obtenerContabilidad({
            empresaId: studentSession.empresaId,
            id: studentSession.id,
          });

          // Calculamos el importe total de los recibos
          let total = 0;
          let pendientes = 0;

          dataContabilidad.forEach((element) => {
            total += parseFloat(element.importe);
            const fechaActual = new Date();
            const fechaVTO = new Date(element.fechaVTO);
            // Revisamos si se cumple la condición para añadir el símbolo de exclamación
            if (
              (element.idEstado === 0 && fechaVTO < fechaActual) ||
              (element.idEstado === 2 && fechaVTO < fechaActual)
            ) {
              pendientes++;
            }
          });

          if (pendientes >= 3) {
            setRecibosPendientes(true);
          }

          setDataApi(dataContabilidad);
          setTotalRecibos(total);
          setDataLoaded(true);
        } catch (error) {
          setDataApi([]);
          setTotalRecibos(0);
          setDataLoaded(false);
        }
      };

      fetchData();
    }, []);

    console.log(dataApi, 'yee')
    return(
        <TabPane tabId="6">
            {
                dataApi.length === 0 && !dataLoaded ? (
                    <Loader />
                ) : dataApi.length === 0 ? (
                    <p>No se han encontrado recibos.</p>
                ) : (
                    <>
                        <Table responsive borderless style={{ fontSize:"13px", borderCollapse: "separate", borderSpacing:"0 1em" }}>
                            <thead>
                                <tr>
                                    <th>ID Matrícula</th>
                                    <th>Grupo</th>
                                    <th>ID Recibo</th>
                                    <th>ID Factura</th>
                                    <th>Concepto</th>
                                    <th>Fecha VTO</th>
                                    <th>Fecha cobro</th>
                                    <th>Importe</th>
                                    <th>Forma de pago</th>
                                    <th>Estado</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    dataApi.map((r) => (
                                        <>
                                            <tr key={ r.numFactura } >
                                                {/* <td id={r.codigo} className ={r.Estado !== 'Pendiente' ? 'text-black-50 ': ''}>
                                                    <InputRecibo key={r.NUM_Recibo} id={r.NUM_Recibo} concepto={r.Concepto} neto={r.Neto} estado={r.Estado} onReciboChange={handleReciboChange} listadoRecibos={listadoRecibos} setListadoRecibos={setListadoRecibos}/>
                                                </td> */}
                                                <td className={
        r.estado === 'Cobrado' 
            ? 'text-black-50' 
            : ((r.estado === 'Pendiente'|| r.estado === 'Devuelto') && new Date(r.fechaVTO) < new Date())
            ? 'text-danger' 
            : ''
    }>
        {r.idMatricula}
    </td>

<td className={
    r.estado === 'Cobrado' 
        ? 'text-black-50' 
        : ((r.estado === 'Pendiente'|| r.estado === 'Devuelto') && new Date(r.fechaVTO) < new Date())
        ? 'text-danger' 
        : ''
}>
    {r.grupo}
</td>
<td className={
    r.estado === 'Cobrado' 
        ? 'text-black-50' 
        : ((r.estado === 'Pendiente'|| r.estado === 'Devuelto') && new Date(r.fechaVTO) < new Date())
        ? 'text-danger' 
        : ''
}>
    {r.recibo}
</td>
<td className={
    r.estado === 'Cobrado' 
        ? 'text-black-50' 
        : ((r.estado === 'Pendiente'|| r.estado === 'Devuelto') && new Date(r.fechaVTO) < new Date())
        ? 'text-danger' 
        : ''
}>
    {r.numFactura}
</td>
<td className={
    r.estado === 'Cobrado' 
        ? 'text-black-50' 
        : ((r.estado === 'Pendiente'|| r.estado === 'Devuelto') && new Date(r.fechaVTO) < new Date())
        ? 'text-danger' 
        : ''
}>
    {r.concepto}
</td>
<td className={
    r.estado === 'Cobrado' 
        ? 'text-black-50' 
        : ((r.estado === 'Pendiente'|| r.estado === 'Devuelto') && new Date(r.fechaVTO) < new Date())
        ? 'text-danger' 
        : ''
}>
    {new Intl.DateTimeFormat('es-ES', opcionesFecha).format(new Date(r.fechaVTO))}
</td>
<td className={
    r.estado === 'Cobrado' 
        ? 'text-black-50' 
        : ((r.estado === 'Pendiente'|| r.estado === 'Devuelto') && new Date(r.fechaVTO) < new Date())
        ? 'text-danger' 
        : ''
}>
    {r.fechaCobro === 'No cobrado' ? r.fechaCobro : new Intl.DateTimeFormat('es-ES', opcionesFecha).format(new Date(r.fechaCobro))}
</td>
<td className={
    r.estado === 'Cobrado' 
        ? 'text-black-50' 
        : ((r.estado === 'Pendiente'|| r.estado === 'Devuelto') && new Date(r.fechaVTO) < new Date())
        ? 'text-danger' 
        : ''
}>
    {r.importe} €
</td>
<td className={
    r.estado === 'Cobrado' 
        ? 'text-black-50' 
        : ((r.estado === 'Pendiente'|| r.estado === 'Devuelto') && new Date(r.fechaVTO) < new Date())
        ? 'text-danger' 
        : ''
}>
    {r.formaPago}
</td>
<td className={
    r.estado === 'Cobrado' 
        ? 'text-black-50' 
        : ((r.estado === 'Pendiente'|| r.estado === 'Devuelto') && new Date(r.fechaVTO) < new Date())
        ? 'text-danger' 
        : ''
}>
    {r.estado}
</td>
                                            </tr>    
                                        </>
                                    ))
                                }
                            </tbody>
                        </Table >
                        <span className='text-danger'>Importe total de los recibos: {totalRecibos.toFixed(2)} €</span>
                    </>
                )
            }
        </TabPane>
    )
}

Contabilidad.propTypes = {
    setRecibosPendientes: PropTypes.any
};

export default Contabilidad;
