import { useEffect, useState } from "react"
import { Input } from "reactstrap" 
import PropTypes from 'prop-types';
import matriculacionApiService from "../../../services/http/matriculacionApiService";

const EstadoRecibo = ({setIsLoading, isLoading, actual, setIdEditados, idEditados, numRecibo}) => {
    const [data, setData] = useState('')
console.log(actual, 'yee')
    useEffect(() => {
      const fetchData = async () => {
        try {
          const dataEstadosRecibo = await matriculacionApiService.cargarEstadosRecibo({
            headquarter: 'CEDESCA',
          });
          //setGroup(actualData.groups[0].titulo)
          setData(dataEstadosRecibo);
        } catch (error) {
          setData('');
        } finally {
          setIsLoading(false);
        }
      };

      fetchData();
    }, []);

 
    const handleOnChange = (e) => {
        const nuevoArray = idEditados.filter(objeto => objeto.id !== e.target.id);
        const reciboEditado = {
            id: e.target.id,
            estado: e.target.value,
        }
        setIdEditados([...nuevoArray, reciboEditado])
    }

    return (
        <>
            {isLoading || data.length === 0 ? '' : <Input type="select" name={numRecibo}  id={numRecibo}  onChange={handleOnChange} defaultValue={actual} style={{
        width: "93px",
        height: "30px",
        fontSize: "13px",
        padding: "5px",
      }}>
                            {data.map(datos => <option key={datos.id} value={datos.id}>{datos.descripcion}</option>)}
            </Input> }
            
        </>
    )
}
EstadoRecibo.propTypes = {
    isLoading: PropTypes.any,
    setIsLoading: PropTypes.any,
    actual: PropTypes.any,
    idEditados: PropTypes.any,
    setIdEditados: PropTypes.any,
    numRecibo: PropTypes.any
  };
export default EstadoRecibo